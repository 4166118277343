import React from 'react'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'

import Divider from 'src/components/Library/Divider/Divider'
import Switch from 'src/components/Library/Switch/Switch'

const TemplateSettings = ({ isTemplate, placeholderText }) => {
  return (
    <Stack spacing={2}>
      <h1 className="text-gray-900 text-md text-base leading-6 font-medium">
        Template Settings
      </h1>
      <Divider size={'h-[1px]'} className={'px-0'} />
      <Card elevation={0} variant={'outlined'}>
        <CardHeader
          className={'bg-gray-50'}
          title={
            <div className="flex flex-row">
              <div className="w-1/2 flex flex-row items-center text-gray-600">
                <h3 className="font-normal text-base">Template</h3>
              </div>
              <div className="flex flex-row items-center w-1/2 justify-end">
                <Switch
                  checked={isTemplate.value}
                  onChange={() => {
                    isTemplate.toggle()
                  }}
                />
              </div>
            </div>
          }
          subheader={
            <p className="text-xs text-gray-500 pt-3">
              Allow this {placeholderText} to be used as a template when
              creating a new {placeholderText}.
            </p>
          }
        />
      </Card>
    </Stack>
  )
}

export default TemplateSettings

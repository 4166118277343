export enum SettingsViews {
  GENERAL,
  ACCESS,
  CLONING,
  MOVE,
  TEMPLATE,
  PRIVATE,
  ARCHIVE,
}
export const viewTitles = [
  'General',
  'Access',
  'Cloning',
  'Move',
  'Template',
  'Private',
  'Archive',
]

export const defaultConfig = [
  SettingsViews.GENERAL,
  SettingsViews.ACCESS,
  SettingsViews.CLONING,
  SettingsViews.MOVE,
  SettingsViews.TEMPLATE,
  SettingsViews.PRIVATE,
  SettingsViews.ARCHIVE,
]

export const npsCampaignConfig = [SettingsViews.GENERAL]
export const npsMeasurementConfig = [SettingsViews.GENERAL]

export const processMapConfigSuperAdmin = [SettingsViews.TEMPLATE]

export const reactMapConfigSuperAdmin = [
  SettingsViews.GENERAL,
  SettingsViews.TEMPLATE,
]

export const processMapConfig = [SettingsViews.GENERAL]
export const reactMapConfig = []

export const processMapCategoryConfig = [
  SettingsViews.GENERAL,
  SettingsViews.ACCESS,
]

export const reactMapCategoryConfig = [
  SettingsViews.GENERAL,
  SettingsViews.ACCESS,
]

export const goalsConfig = [
  SettingsViews.GENERAL,
  SettingsViews.TEMPLATE,
  SettingsViews.MOVE,
  SettingsViews.ACCESS,
]

export const learnerCategoryConfig = [
  SettingsViews.GENERAL,
  SettingsViews.ACCESS,
]

import React from 'react'

import { TextField } from '@mui/material'
import Stack from '@mui/material/Stack'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

import Divider from 'src/components/Library/Divider/Divider'

const GeneralSettings = ({
  recordTitle,
  handleChange,
  customFields,
  handleCustomFieldChange,
}) => {
  return (
    <>
      <Stack spacing={2} data-testid="general-settings-view">
        <h1 className="text-md text-base font-medium leading-6 text-gray-900">
          General Settings
        </h1>
        <Divider size={'h-[1px]'} className={'px-0'} />
        <Stack spacing={1}>
          <h2
            className={'text-xs font-normal uppercase leading-3 text-gray-600'}
          >
            Title
          </h2>
          <TextField
            variant="outlined"
            fullWidth
            value={recordTitle}
            onChange={handleChange}
          />
          {customFields?.map((field, index) => (
            <Stack spacing={1} key={index}>
              <h2
                className={
                  'text-xs font-normal uppercase leading-3 text-gray-600'
                }
              >
                {field.name}
              </h2>
              {field.type === 'text' && (
                <TextField
                  variant="outlined"
                  fullWidth
                  value={field.value}
                  onChange={(e) =>
                    handleCustomFieldChange(index, e.target.value)
                  }
                />
              )}
              {field.type === 'number' && (
                <TextField
                  variant="outlined"
                  fullWidth
                  className={'mt-2'}
                  value={field.value}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/
                    if (e.target.value === '' || regex.test(e.target.value)) {
                      handleCustomFieldChange(index, e.target.value)
                    }
                  }}
                />
              )}
              {field.type === 'date' && (
                <DatePicker
                  className={'mt-2'}
                  openTo="day"
                  views={['year', 'month', 'day']}
                  value={dayjs(field.value)}
                  onChange={(newValue) => {
                    handleCustomFieldChange(index, newValue)
                  }}
                  sx={{ width: '100%' }}
                />
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

export default GeneralSettings
